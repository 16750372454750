const imageHotspots = document.querySelectorAll('.js-base-hotspot-editable');

if (imageHotspots.length) {
  import(/* webpackChunkName: 'bolt-slideshow' */ './src/image-hotspots').then(
    ({ ImageHotspots }) => {
      imageHotspots.forEach((el) => {
        const imageHotspotsComp = new ImageHotspots(el);
      });
    },
  );
}
